.cookies-container {
  color: #fff;
  z-index: 1000;
  background-color: #7182b6;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
}

.cookies-container.active {
  display: block;
}

.cookies-container .text-wrapper {
  flex-direction: column;
  padding: 16px 40px 16px 24px;
  display: flex;
}

.cookies-container .title {
  font-weight: 700;
}

.cookies-container .description {
  align-items: center;
  line-height: 24px;
  display: flex;
}

.cookies-container .description a {
  color: #fff;
}

.cookies-container .description .button {
  margin: 0 40px;
  padding: 4px 50px;
  font-size: 16px;
  font-weight: 700;
}

.cookies-container .close-icon {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

@media only screen and (width <= 630px) {
  .cookies-container .text-wrapper {
    padding: 16px;
  }

  .cookies-container .text-wrapper .description {
    flex-direction: column;
    display: flex;
  }

  .cookies-container .text-wrapper .description span {
    margin-right: 40px;
  }

  .cookies-container .text-wrapper .description .button {
    text-align: center;
    width: 100%;
    margin-top: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
  }
}
/*# sourceMappingURL=index.dd9e56ce.css.map */
