@import '/packages/site/src/public/styles/colors.scss';

.cookies-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $text_03;
  color: $ui_01;
  z-index: 1000;
  display: none;

  &.active {
    display: block;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 40px 16px 24px;
  }

  .title {
    font-weight: 700;
  }

  .description {
    display: flex;
    line-height: 24px;
    align-items: center;

    a {
      color: $ui_01;
    }

    .button {
      padding: 4px 50px;
      font-weight: 700;
      font-size: 16px;
      margin: 0 40px;
    }
  }

  .close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 630px) {
  .cookies-container { 
    .text-wrapper {
      padding: 16px;

      .description {
        display: flex;
        flex-direction: column;

        span {
          margin-right: 40px;
        }
  
        .button {
          margin-top: 16px;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          width: 100%;
        }
      }
    } 
  }
}